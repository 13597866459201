var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticClass:"tw-flex tw-flex-wrap tw-gap-y-2 tw-text-3.5 tw-text-storm tw-list-none tw-p-0",attrs:{"data-id":_vm.uid,"data-name":"breadcrumb"}},[(_vm.home)?_c('li',{class:{
			'tw-hidden': _vm.BREAKPOINT.IS_MOBILE || !_vm.showHome,
			'breadcrumb__item--with-separator':
				(!_vm.BREAKPOINT.IS_MOBILE || _vm.isStatic) &&
				_vm.showHome &&
				(_vm.items.length || _vm.parent || (_vm.showCurrent && _vm.current)),
		}},[_c('RouterLink',{staticClass:"tw-underline",class:{ 'tw-text-4 tw-px-3 tw-py-3.5': _vm.isBigVariant },attrs:{"data-name":"breadcrumb-home","to":_vm.home.href}},[_vm._v(" "+_vm._s(_vm.home.text)+" ")])],1):_vm._e(),(_vm.items.length)?_vm._l((_vm.items),function(item,index){return _c('li',{key:item.href,class:{
				'tw-hidden md:tw-block': !_vm.isStatic,
				'breadcrumb__item--with-separator':
					(!_vm.BREAKPOINT.IS_MOBILE || _vm.isStatic) &&
					(index === _vm.items.length - 1 ? _vm.parent || (_vm.showCurrent && _vm.current) : true),
			}},[(item.href)?_c('RouterLink',{staticClass:"tw-underline",class:{ 'tw-text-4 tw-px-3 tw-py-3.5': _vm.isBigVariant },attrs:{"data-id":("breadcrumb-links-" + (index + 1)),"data-name":"breadcrumb-links","title":item.text,"to":item.href}},[_vm._v(" "+_vm._s(item.text)+" ")]):[_vm._v(" "+_vm._s(_vm.home.text)+" ")]],2)}):_vm._e(),(_vm.parent)?_c('li',{class:{
			'breadcrumb__item--with-separator':
				(!_vm.BREAKPOINT.IS_MOBILE || _vm.isStatic) && _vm.showCurrent && _vm.current,
		}},[(_vm.parent.href)?_c('RouterLink',{class:{
				'tw-no-underline': !_vm.isBigVariant,
				'tw-text-4 tw-px-3 tw-py-3.5': _vm.isBigVariant,
			},attrs:{"data-name":"breadcrumb-parent","title":_vm.parent.text,"to":_vm.parent.href}},[_c('Ico',{class:['tw-text-black tw-mr-5 md:tw-hidden', { 'tw-hidden': _vm.isStatic }],attrs:{"name":"arrow-left","size":"16px"}}),_c('span',{staticClass:"tw-underline"},[_vm._v(_vm._s(_vm.parent.text))])],1):[_vm._v(" "+_vm._s(_vm.parent.text)+" ")]],2):_vm._e(),(_vm.current)?_c('li',{class:{
			'tw-hidden': _vm.BREAKPOINT.IS_MOBILE || !_vm.showCurrent,
			'tw-text-4 tw-px-3 tw-py-3.5': _vm.isBigVariant,
		},attrs:{"data-name":"breadcrumb-current"}},[_vm._v(" "+_vm._s(_vm.current)+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }