import Vue from 'vue';
import { Route } from 'vue-router';

// This is a hack to fix DY issue with their auto recognition of page type
// Without this fix, the context will be updated correctly by nuxt, but then after about 1 second
// overwritten by DY logic provided in api_static.js .
// DY-Support knows about this overwriting bug (support ticket #82313).

let dyUpdateRetryTimeout;

function watchDyRecommendationContext(
	to: Route,
	iterationCount = 0,
	retryAfterMs = 100,
	timeout?: number,
) {
	const requestedDyType = to.meta?.dynamicYieldContext?.type;

	if (!(window as any)?.DY || !requestedDyType) {
		return;
	}

	if (!timeout) {
		const timeoutDate = new Date();
		timeoutDate.setSeconds(timeoutDate.getSeconds() + 5);
		timeout = timeoutDate.getTime();
	}

	if (
		(window as any).DY?.recommendationContext?.type !== requestedDyType &&
		location.href.includes(to.fullPath)
	) {
		const dyContextPageElement = document.querySelector(
			'script[data-hid=DY_recommendationContext]',
		);

		if (dyContextPageElement) {
			// eslint-disable-next-line no-eval
			eval((dyContextPageElement as HTMLScriptElement).innerText);
			return;
		}
	}

	if (Date.now() < timeout) {
		iterationCount++;
		dyUpdateRetryTimeout = setTimeout(
			() =>
				watchDyRecommendationContext(to, iterationCount, iterationCount < 20 ? 100 : 250, timeout),
			retryAfterMs,
		);
	}
}

export const DyContextMixin = Vue.extend({
	beforeRouteUpdate(to: Route, _from: Route, next): void {
		if (process.client) {
			watchDyRecommendationContext(to);
		}

		next();
	},
	beforeRouteLeave(_to: Route, _from: Route, next): void {
		if (dyUpdateRetryTimeout) {
			clearTimeout(dyUpdateRetryTimeout);
		}

		next();
	},
});
